import baseURL from "@/utils/config";
import request from "@/plugins/axios"
// 查看物流/api/getNavigationList

// 获取关于我们类型
export function getAboutCategory(data){
  return request({
      url: baseURL.api+'/api/searchAboutUss',
      method: "post",
      data,
  });
}
//获取所有类型
export function getAllAboutCategory(data){
  return request({
      url: baseURL.api+'/api/getAboutUsCategory',
      method: "post",
      data,
  });
}